import React from 'react';
import { useDispatch } from 'react-redux';
import AppAction from '@/reducers/app/action';
import RoutePaths from '@/utils/routes';
import { LoadingPage } from '@/components/loading/loadingBase';
import { clearCache } from '@/utils/utils';

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    localStorage.clear();
    clearCache();
    dispatch({
      type: AppAction.RESET_ROOT_STATE,
    });
    window.location.href = RoutePaths.LOGIN;
  }, []);
  return <LoadingPage isLoading={true} />;
};

export default Logout;
